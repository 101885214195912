import {useEffect, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {customersClubsDetail_GET} from '../vendor/redux/actions/customers';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import ReactGA from 'react-ga4';
import {getIsProduction} from '../utils/environments';
import {clubsPolicyList_GET} from '../vendor/redux/actions/policies';
import {Box, LinearProgress} from '@mui/material';
import {
  customersClubsTimingsList_GET,
  customersSportsClubList_GET,
} from '../vendor/redux/actions/scheduleApis';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import ProviderNotistack from '../providers/ProviderNotistack';
import {ERR_NETWORK_ERROR} from '../constants/errors';
import { locationsCountriesList_GET } from '../vendor/redux/actions/locations';
import ClubNotExists from '../components/ClubNotExists/ClubNotExists';
import { getCompanyDetails, getSingleCompanyDetails } from '../vendor/redux/actions/company';
import ReactPixel from 'react-facebook-pixel';
import VersionUpdate from '../components/_Global/VersionUdate/VersionUpdate';
import ProviderCompanyExperience from '../providers/ProviderCompanyExperience';


function InitCompanyExperience() {

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();

  const {clubId, companyId} = useParams();
  const {pathname} = useLocation();
  const prevPathname = useRef();
  const isProduction = getIsProduction();

  const [clubDetails, setClubDetails] = useState();
  const [clubTheme, setClubTheme] = useState('pending');
  const [gTag, setGTag] = useState('pending');
  const [gTagSet, setGTagSet] = useState(false);
  const [fbPixel, setFbPixel] = useState(false);
  const [pixelId, setPixelId] = useState('pending');
  const [clubPolicies, setClubPolicies] = useState();
  const [clubSports, setClubSports] = useState();
  const [clubTimings, setClubTimings] = useState([
    {
        "id": "134536",
        "status": true,
        "day": "Tuesday",
        "startTime": "07:30",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T06:30:40.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:40.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:15.166Z",
        "updatedAt": "2024-10-30T04:47:40.337Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    },
    {
        "id": "134540",
        "status": true,
        "day": "Saturday",
        "startTime": "07:30",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T06:30:44.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:44.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:16.591Z",
        "updatedAt": "2024-10-30T04:47:44.014Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    },
    {
        "id": "134541",
        "status": true,
        "day": "Sunday",
        "startTime": "07:30",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T06:30:47.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:47.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:16.949Z",
        "updatedAt": "2024-10-30T04:47:47.356Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    },
    {
        "id": "134535",
        "status": true,
        "day": "Monday",
        "startTime": "07:00",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T06:00:47.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:47.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:14.806Z",
        "updatedAt": "2024-10-30T04:47:47.362Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    },
    {
        "id": "134537",
        "status": true,
        "day": "Wednesday",
        "startTime": "08:00",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T07:00:50.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:50.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:15.518Z",
        "updatedAt": "2024-10-30T04:47:50.322Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    },
    {
        "id": "134538",
        "status": true,
        "day": "Thursday",
        "startTime": "07:00",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T06:00:53.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:53.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:15.871Z",
        "updatedAt": "2024-10-30T04:47:53.116Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    },
    {
        "id": "134539",
        "status": true,
        "day": "Friday",
        "startTime": "07:30",
        "endTime": "23:30",
        "startDateTimeUtc": "2024-10-30T06:30:53.000Z",
        "endDateTimeUtc": "2024-10-30T22:30:53.000Z",
        "timeZone": "Europe/Amsterdam",
        "createdAt": "2023-09-22T14:22:16.226Z",
        "updatedAt": "2024-10-30T04:47:53.654Z",
        "clubId": "216742",
        "club": {
            "id": "216742"
        }
    }
]);
console.log('clubTimings',clubTimings)

  const [clubNotExists,setClubNotExists] = useState(false);
  const [headersSet, setHeadersSet] = useState();

  const [companyDetails,setCompanyDetails] = useState();

  const isLoadingClubExperience =
      // !clubDetails ||
      // !clubPolicies ||
      // !clubTimings ||
      !companyDetails ||
      !clubSports ||
      !headersSet ||
      // !gTagSet ||
      clubTheme === 'pending';
  function fetchClubDetails() {

    dispatch(customersClubsDetail_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersClubsDetail_GET Success', res);
      setClubDetails(res?.data?.data);
    }

    function cbFail(e) {
      console.log('customersClubsDetail_GET Fail', e);
      const err = e?.response?.data?.data;
      if (err === 'No such club.') {
        setClubNotExists(true);
      }else {
        enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});
      }
    }

  }
  const fetchCompanyDetails=()=>{

    function cbSuccess(res){
                
      const response = res?.data?.data
      setClubTheme(response?.palette || {});
      const toSet = {...response,...response.company}
      setClubPolicies(response?.policy)
      setCompanyDetails(toSet)
      
    }
    
    function cbFailure(res){
        console.log(' !! ERR : ',res)
    }

    dispatch(getSingleCompanyDetails(companyId,cbSuccess,cbFailure))


  }

  function fetchClubPolicies() {
    // dispatch(clubsPolicyList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubsPolicyList_GET Success', res);
      let policies = res?.data?.data;
      setClubPolicies(policies);
    }

    function cbFail(e) {
      console.log('clubsPolicyList_GET Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});
    }

  }

  function fetchClubSports() {
    dispatch(customersSportsClubList_GET(null, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersSportsClubList_GET Success', res);
      setClubSports(res?.data?.data?.sort((a, b) => b.sport.localeCompare(a.sport)));
    }

    function cbFail(e) {
      console.log('customersSportsClubList_GET Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});

    }

  }

  function fetchClubTimings() {

    dispatch(customersClubsTimingsList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersClubTimingList_GET Success', res);
      setClubTimings(res?.data?.data);
    }

    function cbFail(e) {
      console.log('customersClubTimingList_GET Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});

    }
  }
  function fetchCountries() {

    dispatch(locationsCountriesList_GET(cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('countries list Success', res);
    }

    function cbFail(e) {
      console.log('countries list Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR),{variant:'error'});

    }
  }

  const setupPixels=(pixelsId)=>{

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(pixelsId, {} , options);
    ReactPixel.pageView(); // For tracking page view

  }

  /*Fetch Club Details*/
  useEffect(() => {
    // fetchClubDetails();
    axios.defaults.headers.common['x-clubos-company'] = companyId;
    setHeadersSet(true);

    // fetchClubPolicies();
    fetchClubSports();
    fetchCompanyDetails();

    // fetchClubTimings();
    // fetchCountries();
  }, []);

  // useEffect(()=>{
  //   if(pathname.includes('all-clubs')) {
  //     axios.defaults.headers.common['x-clubos-club'] = '';
  //     axios.defaults.headers.common['x-clubos-club-info'] = '';
  //     fetchClubSports('company');
      
  //   }
  //   else if (prevPathname.current && prevPathname.current.includes('all-clubs')) {
  //     axios.defaults.headers.common['x-clubos-club'] = clubId;
  //     axios.defaults.headers.common['x-clubos-club-info'] = clubId;
  //     fetchClubSports();
  //   }
  //   prevPathname.current = pathname;
  // },[
  //   pathname
  // ])

  useEffect(() => {
    if (clubDetails) {
      fetchClubPolicies();
      fetchClubSports();
      fetchClubTimings();
      fetchCompanyDetails();
    }
  }, [clubDetails]);

  /*Init*/
  useEffect(() => {

    if (clubDetails) {
      //Set Headers
      const clubId = clubDetails?.clubId;
      const companyId = clubDetails?.companyId;
      axios.defaults.headers.common['x-clubos-company'] = companyId;
      axios.defaults.headers.common['x-clubos-club'] = clubId;
      axios.defaults.headers.common['x-clubos-club-info'] = clubId;
      setHeadersSet(true);
      //Set Club Theme
      setClubTheme(clubDetails?.theme || {});
      //Set Google Analytics
      setGTag(clubDetails?.googleAnalyticsId || null);
      setPixelId(clubDetails?.metaPixel ?? null)
    }
  }, [clubDetails]);

  /*Google Analytics Initialize if Google Analytics ID Exists */
  useEffect(() => {
    if (gTag !== 'pending') {
      if (isProduction && gTag) {
        ReactGA.initialize(gTag);
      }
      setGTagSet(true);
    }
  }, [gTag]);
 
  useEffect(() => {
    if (pixelId !== 'pending') {
      if (pixelId) {
        setupPixels(pixelId);
      }
      setFbPixel(true);
    }
  }, [pixelId]);

  /*Club favicons*/
  useEffect(() => {
    //todo: following solution does not work on safari
    const link16 = document.querySelector(
        'link[rel="icon"][type="image/png"][sizes="16x16"]') || {};
    const link32 = document.querySelector(
        'link[rel="icon"][type="image/png"][sizes="32x32"]') || {};
    const linkAppleTouch = document.querySelector(
        'link[rel="apple-touch-icon"][type="image/png"]') || {};

    if (clubId === '216790' || clubId === '216742') {
      link16.href = './favicons/plaza-padel/favicon_pp_16x16.png';
      link32.href = '/favicons/plaza-padel/favicon_pp_32x32.png';
      linkAppleTouch.href = '/favicons/plaza-padel/favicon_pp_512x512.png';
    }
    if (clubId === '216807') {
      link16.href = './favicons/padel-alpha/favicon-pa-16x16.png';
      link32.href = '/favicons/padel-alpha/favicon-pa-32x32.png';
      linkAppleTouch.href = '/favicons/padel-alpha/favicon-pa-512x512.png';
    }

  }, []);

  /*Clean up*/
  useEffect(() => {
    return () => {
      // Club + Company Request Headers Cleanup
      delete axios.defaults.headers.common['x-clubos-company'];
      delete axios.defaults.headers.common['x-clubos-club'];
      delete axios.defaults.headers.common['x-clubos-club-info'];
      // GA Cleanup
      ReactGA.reset();
      // Favicon Cleanup
      // todo reset favicons
    };
  }, []);
  if (!isLoadingClubExperience && !clubNotExists) {
  console.log('clubTheme',clubSports)

    return (
        <ProviderCompanyExperience
            clubDetails={clubDetails}
            clubPolicies={clubPolicies}
            fetchClubPolicies={fetchClubPolicies}
            clubSports={clubSports}
            clubTimings={clubTimings}
            companyDetails={companyDetails}
        >
          <ThemeProvider theme={(outerTheme) => {
            return createTheme(outerTheme, clubTheme);
          }}>
            <ProviderNotistack>
              <Outlet />
              {/* {!!clubPolicies?.club?.status? <Outlet/> : <ClubNotExists/>} */}
              <VersionUpdate />
            </ProviderNotistack>
          </ThemeProvider>
        </ProviderCompanyExperience>
    );
  }

  if (isLoadingClubExperience && !clubNotExists) {
    return (
        <Box
            sx={{
              height: '100vh',
              width: '100vw',
              position: 'relative',
            }}
        >
          <LinearProgress sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
          }}/>
        </Box>);
  }

  if (clubNotExists){
    return <ClubNotExists/>
  }

}

export default InitCompanyExperience;