import {statusError, statusLoading, statusSuccess} from './status';
import {
  CUSTOMER_BOOKINGS_SELF_GET_FAILURE,
  CUSTOMER_BOOKINGS_SELF_GET_LOADING,
  CUSTOMER_BOOKINGS_SELF_GET_SUCCESS,
  EVENTS_FAILURE,
  EVENTS_LOADING,
  EVENTS_SUCCESS,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
  CUSTOMER_UPCOMING_BOOKING_LOADING,
  CUSTOMER_UPCOMING_BOOKING_SUCCESS,
  CUSTOMER_UPCOMING_BOOKING_FAILURE,
} from '../constants';
import axios from 'axios';

export const customersBookingsSelf_GET = (
    bookingType, limit, pageNo, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMER_BOOKINGS_SELF_GET_LOADING));

      const response = await axios.get(
          `customers/bookings/self?bookingType=${bookingType}&limit=${limit}&page=${pageNo}`,
          {
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': '',
            },
          });
      dispatch(
          statusSuccess(CUSTOMER_BOOKINGS_SELF_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(CUSTOMER_BOOKINGS_SELF_GET_FAILURE, e));
    }
  };
};

export const openMatchListingTable_GET = (clubId,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
       const response = await axios.get(
          `/customers/open-match/listing/self?clubId=${clubId}`,
          {
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': '',
            },
          });
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const allEvents_GET = (
    eventType, id, limit, pageNo, cbSuccess, cbFail) => {
  return async (dispatch) => {
    // &limit=${limit}&page=${pageNo}
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `customers/list/eventsBooking?eventType=${eventType}`);
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
    }
  };
};

export const clubEvents_GET = (
    eventType, id, limit, pageNo, cbSuccess, cbFail,type) => {
  return async (dispatch) => {
    try {

      const headers = id? axios.defaults.headers : {'x-clubos-club': '','x-clubos-club-info': ''}
      const status = 'Published';
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `customers/club/eventsBooking?eventType=${eventType}&clubId=${id}&status=${status}&type=${type}`,{headers});
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
    }
  };
};

export const customersEventBookingsSingle_GET = (
    eventId, userId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `customers/eventBookings/${eventId}?userId=${userId}`
          , {
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': '',
            },
          },
      );
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
    }
  };
};

export const customersClubsDetail_GET = (clubId, cbCallBack, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `customers/clubs/detail/${clubId}`);
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbCallBack(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
    }
  };
};

export const clubInfoBySlug_GET = (slug, cbCallBack, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `clubs/slug/${slug}`);
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbCallBack(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
    }
  };
};

export const clubOffers_GET = (clubId, cbCallBack, cbFail) => {
  return async (dispatch) => {
    try {
      const headers = clubId? axios.defaults.headers : {
        'x-clubos-club': '',
        'x-clubos-club-info': '',
      }

      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `customers/offers/list?clubId=${clubId}`,{headers});
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbCallBack(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
    }
  };
};

export const customersEventBookingsRegistration = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.post(`v2/customers/eventBookings/registration`,
          body);
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
      console.log(e);
    }
  };
};

export const registeredEventsGet = (userId, type, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `customers/registered/eventBookings/${userId}?eventType=${type}`, {
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': '',
            },
          });
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
      console.log(e);
    }
  };
};

export const myClubsGet = (cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.get(
          `/customers/clubs/self?limit=100&page=0`, {
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': '',
            },
          });
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
      console.log(e);
    }
  };
};

export const getClubsForCompany = (companyId,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `/customers/clubs/self?limit=100&page=0`, {
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': '',
            },
          });
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      console.log(e);
    }
  };
};

export const cancelRegistration_PATCH = (id, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.patch(`customers/cancelRegistration/${id}`);
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
      console.log(e);
    }
  };
};

export const customersRemoveRegistration_DELETE = (
    eventId, email, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EVENTS_LOADING));
      const response = await axios.put(
          `customers/removeRegistration`, {
            email,
            eventId,
          });
      dispatch(statusSuccess(EVENTS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(EVENTS_FAILURE, e));
      console.log(e);
    }
  };
};

export const verifyEmail_POST = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(VERIFY_EMAIL_LOADING));
      const response = await axios.post(`customers/verify-email`,
          body);
      dispatch(statusSuccess(VERIFY_EMAIL_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(VERIFY_EMAIL_SUCCESS, e));
      console.log(e);
    }
  };
};

export const resendLinkInEmail_POST = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(VERIFY_EMAIL_LOADING));
      const response = await axios.post(`customers/resend-verification-email`,
          body);
      dispatch(statusSuccess(VERIFY_EMAIL_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(VERIFY_EMAIL_SUCCESS, e));
      console.log(e);
    }
  };
};

export const customersUserStats_GET = (cbCallBack, cbFail) => {
  return async () => {
    try {
      const response = await axios.get('customers/user-stats');
      cbCallBack(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const customerUpcomingBookings = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMER_UPCOMING_BOOKING_LOADING));
      const response = await axios.post(`customers/club-booking-view`,
          body);
      dispatch(statusSuccess(CUSTOMER_UPCOMING_BOOKING_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(CUSTOMER_UPCOMING_BOOKING_FAILURE, e));
      console.log(e);
    }
  };
};