import {useContext} from 'react';
import {ContextCompanyExperience} from '../../providers/ProviderCompanyExperience';

function useCompanyExperienceContext() {

  const companyExperienceContext = useContext(ContextCompanyExperience);

  let clubDetails = companyExperienceContext?.clubDetails;
  let companyDetails = companyExperienceContext?.companyDetails;
  let clubPolicies = companyExperienceContext?.clubPolicies;
  let fetchClubPolicies = companyExperienceContext?.fetchClubPolicies;
  let clubSports = companyExperienceContext?.clubSports;
  let clubTimings = companyExperienceContext?.clubTimings;

  return {
    clubDetails,
    clubPolicies,
    fetchClubPolicies,
    clubSports,
    clubTimings,
    companyDetails
  };
}

export default useCompanyExperienceContext;