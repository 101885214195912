import {useState} from 'react';
import {Avatar, Box, Skeleton, useTheme} from '@mui/material';
import {Business, Person, Shield} from '@mui/icons-material';

function ProfileImageViewer({
  imgPath,
  size = 'lg' || 'md' || 'sm' || 'xs' || 'xxs',
  placeholderStyle = 'user-icon' || 'shield-icon' || 'company-icon' ||
  'initials',
  borderColor,
  borderRadius,
  text,
  stringAvatar,
  loading
}) {

  const hasImage = !!imgPath;

  const theme = useTheme();
  const [isLoadingImg, setIsLoadingImg] = useState(hasImage);

  const isXl = size === 'xl';
  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isSm = size === 'sm';
  const isXs = size === 'xs';
  const isXXs = size === 'xxs';

  let PlaceholderIcon;
  let placeholderIconColor = theme.palette.grey['600'];

  switch (placeholderStyle) {
    case 'user-icon':
      PlaceholderIcon = Person;
      break;
    case 'shield-icon':
      PlaceholderIcon = Shield;
      placeholderIconColor = theme.palette.grey['400'];
      break;
    case 'company-icon':
      PlaceholderIcon = Business;
      break;
    case 'initials':
      PlaceholderIcon = null;
      break;
    default:
      PlaceholderIcon = Person;
  }

  const xlStyles = {
    width: '120px',
    height: '120px',
    svg: {
      width: '100px',
      height: '100px',
    },
  };
  const lgStyles = {
    width: '104px',
    height: '104px',
    svg: {
      width: '60px',
      height: '60px',
    },
  };

  const mdStyles = {
    width: '60px',
    height: '60px',
    svg: {
      width: '30px',
      height: '30px',
    },
  };

  const smStyles = {
    width: '40px',
    height: '40px',
    svg: {
      width: '30px',
      height: '30px',
    },
  };

  const xsStyles = {
    width: '32px',
    height: '32px',
    svg: {
      width: '24px',
      height: '24px',
    },
  };

  const xxsStyles = {
    width: '28px',
    height: '28px',
    svg: {
      width: '20px',
      height: '20px',
    },
  };

  return <Box
      className='profileImage'
      sx={{
        ...(isXl && xlStyles),
        ...(isLg && lgStyles),
        ...(isMd && mdStyles),
        ...(isSm && smStyles),
        ...(isXs && xsStyles),
        ...(isXXs && xxsStyles),
        borderRadius: borderRadius ?? '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.grey[100],
        border: !isLoadingImg && '1px solid',
        borderColor: borderColor ?? theme.palette.grey[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        flexShrink: 0,
        'img': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}
  >
    {
        hasImage && (loading || isLoadingImg) && <Skeleton
            animation={'wave'}
            variant={'circular'}
            sx={{
              ...(isXl && xlStyles),
              ...(isLg && lgStyles),
              ...(isSm && smStyles),
              ...(isXs && xsStyles),
              ...(isXXs && xxsStyles),
            }}
        />
    }

    {
        hasImage && <img
            src={imgPath}
            alt="profile-img"
            style={isLoadingImg ? {display: 'none'} : {}}
            onLoad={() => {
              setIsLoadingImg(false);
            }}
        />
    }
    {
        !hasImage && PlaceholderIcon &&
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          'svg': {
            fill: placeholderIconColor,
          },
        }}>
          <PlaceholderIcon/>

        </Box>
    }

    {
        !hasImage && placeholderStyle=='initials' &&
        <Avatar className='initials' {...stringAvatar(text)}/>
    }
  </Box>;
}

export default ProfileImageViewer;
