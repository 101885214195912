import axios from "axios";

export const getCompanyDetails = (clubId, cbSuccess, cbFailure) => {
    return async (dispatch) => {
      try {
        
        const response = await axios.get(`/clubs/club-company/${clubId}`);
        cbSuccess(response);
      } catch (e) {
        cbFailure(e);
    }
    };
};

export const getSingleCompanyDetails = (companyId, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      // customers/company
      const response = await axios.get(`/customers/company/${companyId}`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
  }
  };
};