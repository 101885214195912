import {createContext} from 'react';

export const ContextCompanyExperience = createContext();

function ProviderCompanyExperience({
                                  clubDetails,
                                  clubPolicies,
                                  fetchClubPolicies,
                                  clubSports,
                                  clubTimings,
                                  companyDetails,
                                  children,
                                }) {
  return (
      <ContextCompanyExperience.Provider
          value={{
            clubDetails,
            clubPolicies,
            fetchClubPolicies,
            clubSports,
            clubTimings,
            companyDetails,
          }}>
        {children}
      </ContextCompanyExperience.Provider>
  );
}

export default ProviderCompanyExperience;