import {Box} from '@mui/material';
import {LAYOUT_MAIN__PAGE_PADDING_BOTTOM} from '../../constants/ui';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import ContainerFixed from '../../components/_Global/Containers/ContainerFixed';

function LayoutCompanyPage({children}) {
  return (
      <Box>
        <ContainerFixed>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                pt: 5,
                paddingBottom: LAYOUT_MAIN__PAGE_PADDING_BOTTOM,
              }}
          >
            {children}
          </Box>
        </ContainerFixed>
        <SiteFooter container={'fixed'}/>
      </Box>
  );
}

export default LayoutCompanyPage;
