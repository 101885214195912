import Intercom from "@intercom/messenger-js-sdk";
import useUser from '../hooks/access/useUser';

const IntercomComponent=props=>{

    
  const user = useUser();
  const userObj = user?.userObj;

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_API_ID,
    user_id: userObj?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: user? `${userObj?.firstName} ${userObj?.lastName}`:'', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userObj?.email ?? '', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: user?.createdAt ?? '', // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  window.intercomSettings = {
    app_id: process.env.REACT_APP_INTERCOM_API_ID,
    alignment: 'right',        // Left or right position on the page
    horizontal_padding: 50,   // Horizontal padding
    vertical_padding: 40      // Vertical padding
  };

  return(
    <>
    </>
  )

}
export default IntercomComponent;