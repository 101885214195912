import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import PagePasswordProtected
  from '../pages/PasswordProtected/PagePasswordProtected';
import {getIsProduction} from '../utils/environments';
import IntercomComponent from '../init/IntercomComponent';

function AccessMaster() {

  const isProduction = getIsProduction();

  const [hasAccess, setHasAccess] = useState(
      !!localStorage.getItem('masterAccess'));

  if (isProduction){
    return <>
      <IntercomComponent/>
      <Outlet/>
    </>;
  }

  if (!isProduction){
    if (hasAccess) {
      return <>
        <IntercomComponent/>
        <Outlet/>
      </>;
    } else {
      return <PagePasswordProtected setHasAccess={setHasAccess}/>;
    }
  }

}

export default AccessMaster;
