import {createContext} from 'react';

export const ContextClubExperience = createContext();

function ProviderClubExperience({
                                  clubDetails,
                                  clubPolicies,
                                  fetchClubPolicies,
                                  clubSports,
                                  clubTimings,
                                  companyDetails,
                                  children,
                                }) {

  return (
      <ContextClubExperience.Provider
          value={{
            clubDetails,
            clubPolicies,
            fetchClubPolicies,
            clubSports,
            clubTimings,
            companyDetails,
          }}>
        {children}
      </ContextClubExperience.Provider>
  );
}

export default ProviderClubExperience;