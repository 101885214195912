import * as Sentry from '@sentry/react';

function ProviderSentry({children}) {

  const env = process.env.REACT_APP_ENV;
  const isLocal = env === 'local';

  if (!isLocal) {
    Sentry.init({
      dsn: 'https://6f753ed42f682c1d7215527b0bab08d3@o4508205600145408.ingest.de.sentry.io/4508206328184912',
      // integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0,
      environment: env,
    });
  }
  return <Sentry.ErrorBoundary>
    {children}
  </Sentry.ErrorBoundary>;
}

export default ProviderSentry;
