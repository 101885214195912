import {RESET_REDUX_STATE, USER_LOGOUT} from '../../vendor/redux/constants';
import {persistor} from '../../vendor/redux/configure-store';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

function useLogout(onLogoutSuccess=()=>{}) {

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {clubId, companyId} = useParams();
  const navigateTo = useNavigate();

  function handleUserLogout(notToReroute,onSuccessText) {
    localStorage.removeItem('selectedClub');
    localStorage.removeItem('user');
    localStorage.removeItem('persist:app');
    localStorage.removeItem('persistStates');
    dispatch({type: RESET_REDUX_STATE});
    dispatch({type: USER_LOGOUT});
    persistor.purge();
    onLogoutSuccess();
    if(!notToReroute){
      navigateTo(clubId ?`/club/${clubId}` : companyId ? `/company/${companyId}` : '/login')
    }
    enqueueSnackbar(onSuccessText ?? 'You have successfully logged out', {variant: 'success'});
  }

  return {handleUserLogout};
}

export default useLogout;