import {Box} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import { Suspense } from 'react';
import LayoutCompanyHeader from './LayoutCompanyHeader';
import LayoutCompanyPage from './LayoutCompanyPage';

function LayoutCompany() {

  const pageRef = useRef();
  const { pathname } = useLocation();
  useEffect(() => {

    if (pageRef.current){
       pageRef.current.scrollTo({
         top: 0,
         behavior: "instant",
       });
    }

  }, [pathname]);
  return (
      <Box
          ref={pageRef}
          sx={{
            width: '100vw',
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
      >
       
        <LayoutCompanyHeader/>
        <Box component={'main'}>
          <Suspense fallback={<h1></h1>}>
          <LayoutCompanyPage>
            <Outlet/>
          </LayoutCompanyPage>
          </Suspense>
        </Box>
      </Box>
  );
}

export default LayoutCompany;
